/* Styles */
import './styles/main.scss';

/* Bootstrap */
import 'bootstrap';
import '@popperjs/core';

/* Slick */
import 'slick-carousel';
import $ from 'jquery'
import 'jquery-validation';

/* Email JS */
/* import Email from './js/smtp.js'; */

$('.about-us__carousel').slick({
  dots: true,
  fade: true,
  autoplay: true,
  centerMode: true,
  speed: 1000
})

$('.our-clients__carousel').slick({
  slidesToShow: 5,
  arrows: true,
  prevArrow: '<button class="btn slide-arrow prev-arrow"></button>',
  nextArrow: '<button class="btn slide-arrow next-arrow"></button>'
})

/* Form validation */

// Fetch all the forms we want to apply custom Bootstrap validation styles to
const forms = document.querySelectorAll('.form-validation')

// Loop over them and prevent submission
Array.prototype.slice.call(forms)
  .forEach(function (form) {
    form.addEventListener('submit', function (event) {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }

      form.classList.add('was-validated')
    }, false)
  })

/* Email */

/* const form = document.getElementById('contact_form'); */

$("#contact_form").validate({
  rules: {
    name: {
      required: true,
    },
    phone: {
      number: true,
      required: true,
    },
    email: {
      required: true,
      email: true,
    }
  }
});

/* function sendEmail() {
  Email.send({
    Host : "smtp.gmail.com",
    Username : "lmontegu@gmail.com",
    Password : "3SC4P3th3$YST3M#36",
    To : 'lmontegu@gmail.com',
    From : "no-reply@gmail.com",
    Subject : "This is the subject",
    Body : "And this is the body"
  }).then(
    message => alert(message)
  );
} */

/* form.addEventListener('submit', (e) => {
  e.preventDefault();

  sendEmail();
}) */


